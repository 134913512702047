module.exports = {
    /* Specific Form Fields */
    email: new RegExp(/^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/),
    website: new RegExp(/((file|gopher|news|nntp|telnet|http|ftp|https|ftps|sftp):\/\/)?([\w\-_]+\.)+[a-z]{2,6}(:[0-9]{1,5})?((\/[\w-_%;~.]+)+)?(\?[^\s`!()[\]{};'".<>?«»“”‘’]*[^\s`!()[\]{};:'".,<>?«»“”‘’])?/, 'ig'),
    phone: new RegExp(/((\+?)[01][. -]?)?\(?\d{2,3}\)?([.\- ]?\d{2,4})+([.\- ]?\d{3,4})/),
    company: new RegExp(/^([a-z0-9 \-\'\"\(\)\&\$\.\,])+$/, 'i'),
    username: new RegExp(/^[a-z0-9-]+$/),
    voices: new RegExp(/^((http|https):\/\/)?(www\.)?voices\.com/, 'i'),

    /* Specific Validation Rules */
    // TODO: Add more CI Validation rules? https://codeigniter.com/userguide3/libraries/form_validation.html
    alpha_dash: new RegExp(/^([a-z0-9-_])+$/, 'i'),
    alpha_dash_space: new RegExp(/^([a-z0-9-_ ])+$/, 'i'),
    alphanumeric: new RegExp(/[a-z0-9]/, 'i'),
    uppercase: new RegExp(/[A-Z]/),
    lowercase: new RegExp(/[a-z]/),
    number: new RegExp(/[0-9]/),
    dash_underscore: new RegExp(/^[_-]*$/),
    special_character: new RegExp(/[\W_]/),
    date_format_mmddyyyy: new RegExp(/^\d{2}([./-])\d{2}\1\d{4}$/), // mm-dd-yyyy OR mm.dd.yyyy OR mm/dd/yyyy
    date_format_yyyymmdd: new RegExp(/^\d{4}([./-])\d{2}\1\d{2}$/), // yyyy-mm-dd OR yyyy.mm.dd OR yyyy/dd/mm
    date_format_standard: new RegExp(/(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{2}, \d{4}/), // M dd, yy (Jan 23, 2023)
    website_single_field: new RegExp(/^((file|gopher|news|nntp|telnet|http|ftp|https|ftps|sftp):\/\/)?([\w\-_]+\.)+[a-z]{2,6}(:[0-9]{1,5})?((\/[\w-_%;~.]+)+)?(\?[^\s`!()[\]{};'".<>?«»“”‘’]*[^\s`!()[\]{};:'".,<>?«»“”‘’])?$/, 'i'),
    no_utf8mb4: new RegExp(/[\u{10000}-\u{10FFFF}]/u)
};
