class ScrollTo {

    /**
     * Y Offset
     * @return {number}
     */
    static get yOffset() {
        // height of header + 16px buffer
        return -(72 + 16);
    }

    /**
     * Scroll to Element's y Position
     * @param el                {HTMLElement}
     * @param customYOffset     {int}
     */
    static element (el, {
        container = window,
        customYOffset = 0
    } = {}) {
        if (el) {
            const y = (
                el.getBoundingClientRect().top
                + window.pageYOffset
                + -(customYOffset)
                + ScrollTo.yOffset
            );
            container.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    /**
     * Scroll to top of page
     */
    static top() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

module.exports = ScrollTo;
